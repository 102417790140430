import React from 'react';
import { styled } from 'linaria/react';
import { InlineImage } from '../../ui/InlineImage';
import Image from '@jetshop/ui/Image/Image';
import { convertPropsToObject } from '../ContentRender';
import { theme } from '../../Theming/Theming';
import { ContentHtml } from '../ContentText';
import { ContentLink } from '../ContentLink';

const ImageComponentWrapper = styled.div`
  .image-component-inner {
    position: relative;
    &.box-right,
    &.box-left {
      display: flex;
      flex-wrap: wrap;
      > * {
        width: 100%;
        &.image-text {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          ${theme.below.sm} {
            height: 100%;
            justify-content: space-between;
          }
        }
        ${theme.above.md} {
          &.image-wrapper:not(.long-text) {
            width: 60%;
          }
          &.image-text:not(.long-text) {
            width: 40%;
          }
        }
        ${theme.above.lg} {
          &.image-wrapper.long-text {
            width: 50%;
          }
          &.image-text.long-text {
            width: 50%;
          }
        }
      }
    }
    &.box-left {
      ${theme.above.md} {
        .image-wrapper {
          order: 1;
        }
      }
    }
    &.no-media {
      height: 100%;
    }
  }
`;

const ImageWrapper = styled('div')`
  display: block;
  overflow: hidden;
  &.circle {
    background: white;
    border-radius: 50%;
  }
`;
const VideoWrapper = styled('div')`
  position: relative;
  video {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
const TextContent = styled(ContentHtml)`
  &:not(.no-media) {
    position: absolute;
    z-index: 2;
    padding: 5%;

    > *:last-child {
      margin-bottom: 0 !important;
    }

    //POSIITONS
    &.top-left,
    &.top-center,
    &.top-right {
      top: 0;
    }
    &.middle-left,
    &.middle-center,
    &.middle-right {
      top: 50%;
    }
    &.bottom-left,
    &.bottom-center,
    &.bottom-right {
      bottom: 0;
    }

    &.top-left,
    &.middle-left,
    &.bottom-left {
      left: 0;
    }
    &.top-center,
    &.middle-center,
    &.bottom-center {
      text-align: center;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &.top-right,
    &.middle-right,
    &.bottom-right {
      text-align: right;
      left: 30%;
      right: 0;
    }

    &.middle-left,
    &.middle-right {
      transform: translateY(-50%);
    }

    &.middle-center {
      transform: translateX(-50%) translateY(-50%);
    }

    &.full-left,
    &.full-center,
    &.full-right {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    }
    &.full-left {
      align-items: flex-start;
    }
    &.full-center {
      align-items: center;
      text-align: center;
    }
    &.full-right {
      align-items: flex-end;
    }

    &.under {
      text-align: left;
      position: static;
      padding-bottom: 10%;
      &.light {
        background: ${theme.colors.backgroundDark};
      }
    }

    &.box-left,
    &.box-right {
      text-align: center;
      position: static;
      background: ${theme.colors.backgroundLight};
      &.light {
        background: ${theme.colors.backgroundDark};
      }
    }
    &.box-overlay {
      background: ${theme.colors.backgroundLight};
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
      ${theme.below.md} {
        min-width: 80%;
      }
    }
  }
  &.no-media {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${theme.startPage.row.noImage.backgroundLight};
    button {
      max-width: 300px;
    }
    &.light {
      background: ${theme.startPage.row.noImage.backgroundDark};
    }
    &.top-left,
    &.top-center,
    &.top-right {
      justify-content: flex-start;
    }
    &.middle-left,
    &.middle-center,
    &.middle-right {
      justify-content: center;
    }
    &.bottom-left,
    &.bottom-center,
    &.bottom-right {
      justify-content: flex-end;
    }

    &.top-left,
    &.middle-left,
    &.bottom-left {
      align-items: flex-start;
      text-align: left;
    }
    &.top-center,
    &.middle-center,
    &.bottom-center {
      text-align: center;
      align-items: center;
    }
    &.top-right,
    &.middle-right,
    &.bottom-right {
      align-items: flex-end;
      text-align: right;
    }

    &.middle-left,
    &.middle-right {
    }

    &.middle-center {
    }
  }
`;

function getSizes(columns) {
  if (columns === 2) return [1, 1, 1 / 2, 1 / 2];
  else if (columns === 3) return [1, 1, 1 / 2, 1 / 3];
  else if (columns === 4) return [1, 1, 1 / 2, 1 / 4];
  return [1, 1, 1, 1];
}

const Video = ({ aspect, videoUrl }) => {
  const aspectData = aspect.split(':');
  const padding = (aspectData[1] / aspectData[0]) * 100;
  return (
    <VideoWrapper style={{ paddingTop: padding + '%' }}>
      <video autoPlay playsInline muted loop>
        <source src={videoUrl} />
        Your browser does not support the video tag.
      </video>
    </VideoWrapper>
  );
};

const ImageComponentInner = ({ props, imageSizes, aspect }) => {
  const imageUrl = props.imageUrl && props.imageUrl.value;
  const videoUrl = props.videoUrl && props.videoUrl.value;
  const text = props.text.value;
  const textPosition = props.textPosition.value || 'middle-center';
  const textColor = (props.textColor && props.textColor.value) || 'dark';
  const noMedia = imageUrl || videoUrl;

  const isCircle = aspect === 'circle';
  const aspectRatio = isCircle ? '1:1' : aspect;
  const crop = !isCircle;

  const longText = text && text.length > 300;

  return (
    <div
      className={`image-component-inner ${textPosition} ${
        noMedia ? '' : 'no-media'
      }`}
    >
      {imageUrl && (
        <ImageWrapper
          className={`image-wrapper ${isCircle ? 'circle' : ''} ${
            longText && 'long-text'
          }`}
        >
          {aspect === 'inline' ? (
            <InlineImage sizes={imageSizes} src={imageUrl} alt={imageUrl} />
          ) : (
            <Image
              className="image-holder"
              aspect={aspectRatio}
              sizes={imageSizes}
              src={imageUrl}
              alt={imageUrl}
              crop={crop || true}
              cover={crop}
            />
          )}
        </ImageWrapper>
      )}
      {!imageUrl && videoUrl && <Video aspect={aspect} videoUrl={videoUrl} />}
      <TextContent
        className={`image-text ${textPosition} ${textColor} ${
          longText && 'long-text'
        } ${noMedia ? '' : 'no-media'}`}
        content={text}
      />
    </div>
  );
};

const ImageComponent = ({ data, columns, aspect = '1:1', sizes }) => {
  const props = convertPropsToObject(data);
  const link = props.link.value;
  const imageSizes = sizes || getSizes(columns);
  return (
    <ImageComponentWrapper className={`image-component-wrapper`}>
      {link ? (
        <ContentLink to={link}>
          <ImageComponentInner
            props={props}
            imageSizes={imageSizes}
            aspect={aspect}
          />
        </ContentLink>
      ) : (
        <ImageComponentInner
          props={props}
          imageSizes={imageSizes}
          aspect={aspect}
        />
      )}
    </ImageComponentWrapper>
  );
};

export default ImageComponent;
