import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { ContentHtml, contentTextElements } from '../ContentText';

const TextBoxWrapper = styled('div')`
  margin: 0 auto 0;
  width: 100%;
  .content-html-text {
    width: 100%;
    margin: 0 auto;
    ${contentTextElements} {
      padding-left: ${theme.general.pagePadding.sm};
      padding-right: ${theme.general.pagePadding.sm};
    }
    &.center {
      > ul {
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: space-around;
        flex-wrap: wrap;
        > li {
          margin: 15px;
          img {
            height: 25px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  padding: 2rem 0;
  &.primary,
  &.secondary {
    padding: 2rem 0;
  }
  &.primary {
    background: ${theme.startPage.row.background.primary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.primary};
    }
  }
  &.secondary {
    background: ${theme.startPage.row.background.secondary};
    ${contentTextElements} {
      color: ${theme.startPage.row.color.secondary};
    }
  }
`;

const TextBox = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const text = props.text.value;
  const textAlign = props.textAlign.value;
  const textColor = props.textColor.value;
  const background = props.background.value;
  const maxWidth = props.maxWidth && props.maxWidth.value;
  return (
    <TextBoxWrapper className={`${background}`} data-index={index}>
      <ContentHtml
        style={{ maxWidth: maxWidth }}
        className={`content-html-text ${textColor} ${textAlign}`}
        content={text}
      />
    </TextBoxWrapper>
  );
};

export default TextBox;
